var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bigGroud"},[_c('div',{staticClass:"width_1920"},[_vm._m(0),_c('div',{staticClass:"name_lay"},[_c('img',{staticClass:"image_bg",attrs:{"src":require("../../assets/img/sports/show/Pic_Bg_BT.png")}}),(_vm.projectList.length != 0)?_c('div',{staticClass:"name"},[_c('div',{staticClass:"w137 rank_text"},[_vm._v("名次")]),_c('div',{staticClass:"flex normal_text"},[_vm._v("队伍名称")]),(_vm.projectList[0])?_c('div',{class:_vm.projectList[0].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[0].title)+" ")]):_vm._e(),(_vm.projectList[1])?_c('div',{class:_vm.projectList[1].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[1].title)+" ")]):_vm._e(),(_vm.projectList[2])?_c('div',{class:_vm.projectList[2].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[2].title)+" ")]):_vm._e(),(_vm.projectList[3])?_c('div',{class:_vm.projectList[3].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[3].title)+" ")]):_vm._e(),(_vm.projectList[4])?_c('div',{class:_vm.projectList[4].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[4].title)+" ")]):_vm._e(),(_vm.projectList[5])?_c('div',{class:_vm.projectList[5].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[5].title)+" ")]):_vm._e(),(_vm.projectList[6])?_c('div',{class:_vm.projectList[6].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[6].title)+" ")]):_vm._e(),(_vm.projectList[7])?_c('div',{class:_vm.projectList[7].status != 1
            ? 'flex normal_text'
            : 'flex current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[7].title)+" ")]):_vm._e(),_c('img',{staticClass:"title_rank",attrs:{"src":require("../../assets/img/sports/show/Pic_BT_ZJF.png")}})]):_vm._e(),_c('div',{staticClass:"h91"}),_vm._l((_vm.sportslist),function(item,index){return _c('div',{key:index,staticClass:"pos_b"},[_c('div',{staticClass:"black_bg"}),(index % 2 != 0)?_c('div',{staticClass:"blue_bg"}):_vm._e(),_c('div',{staticClass:"label"},[_c('div',{staticClass:"w137 center"},[(item.rank == 1)?_c('img',{attrs:{"src":require("../../assets/img/sports/show/Icon_PM_F.png")}}):_vm._e(),(item.rank == 2)?_c('img',{attrs:{"src":require("../../assets/img/sports/show/Icon_PM_S.png")}}):_vm._e(),(item.rank == 3)?_c('img',{attrs:{"src":require("../../assets/img/sports/show/Icon_PM_T.png")}}):_vm._e(),(item.rank == 4)?_c('img',{attrs:{"src":require("../../assets/img/sports/show/04.png")}}):_vm._e(),(item.rank == 5)?_c('img',{attrs:{"src":require("../../assets/img/sports/show/05.png")}}):_vm._e(),(item.rank == 6)?_c('img',{attrs:{"src":require("../../assets/img/sports/show/06.png")}}):_vm._e(),(item.rank == 7)?_c('img',{attrs:{"src":require("../../assets/img/sports/show/07.png")}}):_vm._e(),(item.rank == 8)?_c('img',{attrs:{"src":require("../../assets/img/sports/show/08.png")}}):_vm._e()]),_c('div',{staticClass:"flex row"},[_c('img',{staticClass:"image",attrs:{"src":item.logo}}),_c('div',{staticClass:"normal_text mrl13"},[_vm._v(_vm._s(item.teamName))])]),(_vm.projectList[0])?_c('div',{class:_vm.projectList[0].status != 1
              ? 'flex score1 center'
              : item.scores[0]
              ? 'flex score2 center'
              : 'flex score3 center'},[_vm._v(" "+_vm._s(item.scores[0] ? item.scores[0] : _vm.projectList[0].status != 1 ? "--" : "进行中")+" ")]):_vm._e(),(_vm.projectList[1])?_c('div',{class:_vm.projectList[1].status != 1
              ? 'flex score1 center'
              : item.scores[1]
              ? 'flex score2 center'
              : 'flex score3 center'},[_vm._v(" "+_vm._s(_vm.projectList[1].status == 1 || _vm.projectList[1].status == 2 ? item.scores[1] ? item.scores[1] : "进行中" : _vm.projectList[0].status == 1 ? "下一项" : "--")+" ")]):_vm._e(),(_vm.projectList[2])?_c('div',{class:_vm.projectList[2].status != 1
              ? 'flex score1 center'
              : item.scores[2]
              ? 'flex score2 center'
              : 'flex score3 center'},[_vm._v(" "+_vm._s(_vm.projectList[2].status == 1 || _vm.projectList[2].status == 2 ? item.scores[2] ? item.scores[2] : "进行中" : _vm.projectList[1].status == 1 ? "下一项" : "--")+" ")]):_vm._e(),(_vm.projectList[3])?_c('div',{class:_vm.projectList[3].status != 1
              ? 'flex score1 center'
              : item.scores[3]
              ? 'flex score2 center'
              : 'flex score3 center'},[_vm._v(" "+_vm._s(_vm.projectList[3].status == 1 || _vm.projectList[3].status == 2 ? item.scores[3] ? item.scores[3] : "进行中" : _vm.projectList[2].status == 1 ? "下一项" : "--")+" ")]):_vm._e(),(_vm.projectList[4])?_c('div',{class:_vm.projectList[4].status != 1
              ? 'flex score1 center'
              : item.scores[4]
              ? 'flex score2 center'
              : 'flex score3 center'},[_vm._v(" "+_vm._s(_vm.projectList[4].status == 1 || _vm.projectList[4].status == 2 ? item.scores[4] ? item.scores[4] : "进行中" : _vm.projectList[3].status == 1 ? "下一项" : "--")+" ")]):_vm._e(),(_vm.projectList[5])?_c('div',{class:_vm.projectList[5].status != 1
              ? 'flex score1 center'
              : item.scores[5]
              ? 'flex score2 center'
              : 'flex score3 center'},[_vm._v(" "+_vm._s(_vm.projectList[5].status == 1 || _vm.projectList[5].status == 2 ? item.scores[5] ? item.scores[5] : "进行中" : _vm.projectList[4].status == 1 ? "下一项" : "--")+" ")]):_vm._e(),(_vm.projectList[6])?_c('div',{class:_vm.projectList[6].status != 1
              ? 'flex score1 center'
              : item.scores[6]
              ? 'flex score2 center'
              : 'flex score3 center'},[_vm._v(" "+_vm._s(_vm.projectList[6].status == 1 || _vm.projectList[6].status == 2 ? item.scores[6] ? item.scores[6] : "进行中" : _vm.projectList[5].status == 1 ? "下一项" : "--")+" ")]):_vm._e(),(_vm.projectList[7])?_c('div',{class:_vm.projectList[7].status != 1
              ? 'flex score1 center'
              : item.scores[7]
              ? 'flex score2 center'
              : 'flex score3 center'},[_vm._v(" "+_vm._s(_vm.projectList[7].status == 1 || _vm.projectList[7].status == 2 ? item.scores[7] ? item.scores[7] : "进行中" : _vm.projectList[6].status == 1 ? "下一项" : "--")+" ")]):_vm._e(),_c('div',{staticClass:"end_l center"},[_vm._v(_vm._s(item.scores[_vm.projectList.length]))])])])})],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top_lay"},[_c('img',{staticClass:"image1",attrs:{"src":require("../../assets/img/sports/show/Pic_Bg_Title.jpg")}}),_c('div',{staticClass:"image1 center image2"},[_c('img',{attrs:{"src":require("../../assets/img/sports/show/Pic_Text_Title_B.png")}})])])}]

export { render, staticRenderFns }