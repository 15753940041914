<template>
  <div class="bigGroud">
  <div class="width_1920">
    <div class="top_lay">
      <img class="image1" src="../../assets/img/sports/show/Pic_Bg_Title.jpg" />
      <div class="image1 center image2">
        <img

          src="../../assets/img/sports/show/Pic_Text_Title_B.png"
        />
      </div>
    </div>
    <div class="name_lay">
      <img class="image_bg" src="../../assets/img/sports/show/Pic_Bg_BT.png" />
      <div v-if="projectList.length != 0" class="name">
        <div class="w137 rank_text">名次</div>
        <div class="flex normal_text">队伍名称</div>
        <div
          v-if="projectList[0]"
          :class="
            projectList[0].status != 1
              ? 'flex normal_text'
              : 'flex current_text center'
          "
        >
          {{ projectList[0].title }}
        </div>
        <div
          v-if="projectList[1]"
          :class="
            projectList[1].status != 1
              ? 'flex normal_text'
              : 'flex current_text center'
          "
        >
          {{ projectList[1].title }}
        </div>
        <div
          v-if="projectList[2]"
          :class="
            projectList[2].status != 1
              ? 'flex normal_text'
              : 'flex current_text center'
          "
        >
          {{ projectList[2].title }}
        </div>
        <div
          v-if="projectList[3]"
          :class="
            projectList[3].status != 1
              ? 'flex normal_text'
              : 'flex current_text center'
          "
        >
          {{ projectList[3].title }}
        </div>
        <div
          v-if="projectList[4]"
          :class="
            projectList[4].status != 1
              ? 'flex normal_text'
              : 'flex current_text center'
          "
        >
          {{ projectList[4].title }}
        </div>
        <div
          v-if="projectList[5]"
          :class="
            projectList[5].status != 1
              ? 'flex normal_text'
              : 'flex current_text center'
          "
        >
          {{ projectList[5].title }}
        </div>
        <div
          v-if="projectList[6]"
          :class="
            projectList[6].status != 1
              ? 'flex normal_text'
              : 'flex current_text center'
          "
        >
          {{ projectList[6].title }}
        </div>
        <div
          v-if="projectList[7]"
          :class="
            projectList[7].status != 1
              ? 'flex normal_text'
              : 'flex current_text center'
          "
        >
          {{ projectList[7].title }}
        </div>
        <img
          class="title_rank"
          src="../../assets/img/sports/show/Pic_BT_ZJF.png"
        />
      </div>
      <div class="h91"></div>
      <div class="pos_b" v-for="(item, index) in sportslist" :key="index">
        <div class="black_bg"></div>
        <div v-if="index % 2 != 0" class="blue_bg"></div>
        <div class="label">
          <div class="w137 center">
            <img
              v-if="item.rank == 1"
              src="../../assets/img/sports/show/Icon_PM_F.png"
            />
            <img
              v-if="item.rank == 2"
              src="../../assets/img/sports/show/Icon_PM_S.png"
            />
            <img
              v-if="item.rank == 3"
              src="../../assets/img/sports/show/Icon_PM_T.png"
            />
            <img
              v-if="item.rank == 4"
              src="../../assets/img/sports/show/04.png"
            />
            <img
              v-if="item.rank == 5"
              src="../../assets/img/sports/show/05.png"
            />
            <img
              v-if="item.rank == 6"
              src="../../assets/img/sports/show/06.png"
            />
            <img
              v-if="item.rank == 7"
              src="../../assets/img/sports/show/07.png"
            />
            <img
              v-if="item.rank == 8"
              src="../../assets/img/sports/show/08.png"
            />
          </div>
          <div class="flex row">
            <img class="image" :src="item.logo" />
            <div class="normal_text mrl13">{{ item.teamName }}</div>
          </div>

          <div
            v-if="projectList[0]"
            :class="
              projectList[0].status != 1
                ? 'flex score1 center'
                : item.scores[0]
                ? 'flex score2 center'
                : 'flex score3 center'
            "
          >
            {{
              item.scores[0]
                ? item.scores[0]
                : projectList[0].status != 1
                ? "--"
                : "进行中"
            }}
          </div>
          <div
            v-if="projectList[1]"
            :class="
              projectList[1].status != 1
                ? 'flex score1 center'
                : item.scores[1]
                ? 'flex score2 center'
                : 'flex score3 center'
            "
          >
            {{
              projectList[1].status == 1 || projectList[1].status == 2
                ? item.scores[1]
                  ? item.scores[1]
                  : "进行中"
                : projectList[0].status == 1
                ? "下一项"
                : "--"
            }}
          </div>
          <div
            v-if="projectList[2]"
            :class="
              projectList[2].status != 1
                ? 'flex score1 center'
                : item.scores[2]
                ? 'flex score2 center'
                : 'flex score3 center'
            "
          >
            {{
              projectList[2].status == 1 || projectList[2].status == 2
                ? item.scores[2]
                  ? item.scores[2]
                  : "进行中"
                : projectList[1].status == 1
                ? "下一项"
                : "--"
            }}
          </div>
          <div
            v-if="projectList[3]"
            :class="
              projectList[3].status != 1
                ? 'flex score1 center'
                : item.scores[3]
                ? 'flex score2 center'
                : 'flex score3 center'
            "
          >
            {{
              projectList[3].status == 1 || projectList[3].status == 2
                ? item.scores[3]
                  ? item.scores[3]
                  : "进行中"
                : projectList[2].status == 1
                ? "下一项"
                : "--"
            }}
          </div>
          <div
            v-if="projectList[4]"
            :class="
              projectList[4].status != 1
                ? 'flex score1 center'
                : item.scores[4]
                ? 'flex score2 center'
                : 'flex score3 center'
            "
          >
            {{
              projectList[4].status == 1 || projectList[4].status == 2
                ? item.scores[4]
                  ? item.scores[4]
                  : "进行中"
                : projectList[3].status == 1
                ? "下一项"
                : "--"
            }}
          </div>
          <div
            v-if="projectList[5]"
            :class="
              projectList[5].status != 1
                ? 'flex score1 center'
                : item.scores[5]
                ? 'flex score2 center'
                : 'flex score3 center'
            "
          >
            {{
              projectList[5].status == 1 || projectList[5].status == 2
                ? item.scores[5]
                  ? item.scores[5]
                  : "进行中"
                : projectList[4].status == 1
                ? "下一项"
                : "--"
            }}
          </div>
          <div
            v-if="projectList[6]"
            :class="
              projectList[6].status != 1
                ? 'flex score1 center'
                : item.scores[6]
                ? 'flex score2 center'
                : 'flex score3 center'
            "
          >
            {{
              projectList[6].status == 1 || projectList[6].status == 2
                ? item.scores[6]
                  ? item.scores[6]
                  : "进行中"
                : projectList[5].status == 1
                ? "下一项"
                : "--"
            }}
          </div>
          <div
            v-if="projectList[7]"
            :class="
              projectList[7].status != 1
                ? 'flex score1 center'
                : item.scores[7]
                ? 'flex score2 center'
                : 'flex score3 center'
            "
          >
            {{
              projectList[7].status == 1 || projectList[7].status == 2
                ? item.scores[7]
                  ? item.scores[7]
                  : "进行中"
                : projectList[6].status == 1
                ? "下一项"
                : "--"
            }}
          </div>
          <div class="end_l center">{{ item.scores[projectList.length] }}</div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      sportslist: [],
      projectList: [],
    };
  },
  mounted() {
    let that = this;
    that.getRankList();
    setInterval(() => {
      setTimeout(() => {
        that.getRankList();
      }, 0);
    }, 5000);
  },
  methods: {
    getRankList() {
      var that = this;
      let data = {};
      that.api.s_sports.getRankList(data).then((res) => {
        if (res.code == 200) {
          this.projectList = res.result.projects;
          this.sportslist = res.result.teamRank;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.display-flex {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.bigGroud{
  width: 100%;
  height: 1200px;
  background: black;
}
.width_1920 {
  width: 90%;
  height: 1200px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../assets/img/sports/show/Pic_Bg_DP.png");
  display: flex;
  flex-direction: column;
}
.w137 {
  width: 123.3px;
}
.top_lay {
  margin-left: 108.9px;
  padding-top: 73px;
  position: relative;
  .image1 {
    width: 1511.1px;
    height: 132px;
  }
  .image2 {
    position: absolute;
    top: 80px;
    left: 0;
  }
}
.name_lay {
  margin-left: 81px;
  margin-top: 59px;
  margin-right: 85.5px;
  position: relative;
  .name {
    display: flex;
    flex-direction: row;
    margin-left: 41.4px;
    position: absolute;
    left: 0;
    top: 0;
    width: 1479.6px;
    height: 91px;
  }
  .rank_text {
    font-size: 21.6px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffead1;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.normal_text {
  font-size: 21.6px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.current_text {
  font-size: 21.6px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffa000;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title_rank {
  margin-right: 30px;
}
.image_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 1561.5px;
}
.h91 {
  height: 91px;
}
.pos_b {
  position: relative;
  width: 1479.6px;
  height: 80px;
  margin-left: 46px;
}
.black_bg {
  position: absolute;
  top: 0;
  left: 0;
  background: #0e1530;
  opacity: 0.6;
  width: 1479.6px;
  height: 80px;
}
.blue_bg {
  position: absolute;
  top: 0;
  left: 0;
  background: #7cb3ff;
  opacity: 0.2;
  width: 1479.6px;
  height: 80px;
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  width: 1479.6px;
  height: 80px;
  display: flex;
  flex-direction: row;
}
.label1 {
  background: rgba(14, 21, 48, 0.2);
  width: 1479.6px;
  height: 80px;
  display: flex;
  flex-direction: row;
  margin-left: 46px;
}
.image {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.mrl13 {
  margin-left: 13px;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.end_l {
  width: 131.4px;
  margin-right: 27px;
  height: 100%;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.9;
}
.flex {
  flex: 1;
}
.score1 {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.9;
}
.score2 {
  font-size: 36px;
  font-family: Impact;
  font-weight: 400;
  color: #ffcc00;
  opacity: 0.9;
}
.score3 {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffcc00;
  opacity: 0.9;
}
</style>
